import React from "react"
import Layout from "../../components/layout"
import CommentsFB from "../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Poemas = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Eterno viaje - Poemas"
        titleTemplate="%s | Eduardo Ceballos"
        description="Eterno viaje"
        canonical="https://eduardoceballos.com/poemas/eterno-viaje"
        openGraph={{
          url: "https://eduardoceballos.com/poemas/eterno-viaje",
          title: "Eterno viaje - Poemas",
          description: "Eterno viaje",
          images: [
            {
              url: "https://eduardoceballos.com/seo/poemas.jpg",
              alt: "Poemas",
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>Eterno viaje</h1>
      <center>
        <p>
          Una substancia, base del ser,
          <br />
          mueve y vincula,
          <br />
          maneja tiempos y nostalgias.
          <br />
          &nbsp;
          <br />
          Un viaje para adentro,
          <br />
          donde está la sangre caminando
          <br />
          la anatomía humana,
          <br />
          descubre músculos, voluntad movida,
          <br />
          en un ademán de inteligencia natural.
          <br />
          &nbsp;
          <br />
          La carne se apoya en el esqueleto,
          <br />
          estantería de huesos que sostiene la vida,
          <br />
          sintetiza historias de siglos,
          <br />
          con abuelos, con amor, con razas viajeras...
          <br />
          &nbsp;
          <br />
          Una búsqueda en cada tramo,
          <br />
          con distintos ingredientes químicos,
          <br />
          traen la memoria de la tierra y de los astros.
          <br />
          Geométricas figuras de la vida
          <br />
          ponen formas y espejos a la existencia,
          <br />
          donde asoman los gestos de la materia,
          <br />
          alquimista enigmática
          <br />
          que todo lo transforma.
          <br />
          &nbsp;
          <br />
          Por el ojo se ama,
          <br />
          por el corazón sube la música
          <br />
          que adentro marca los ritmos.
          <br />
          &nbsp;
          <br />
          Todo es tierra en esta frágil estructura,
          <br />
          pequeña y asombrosa maquinaria
          <br />
          envuelve la vida y la llena de color.
          <br />
          &nbsp;
          <br />
          Allí amanecen temprano los afectos, el amor,
          <br />y el cerebro suelta palabras,
          <br />
          nombrando el maravilloso mundo circundante,
          <br />
          por donde pasan sus vacas,
          <br />y los niños juegan a la guerra
          <br />
          que hombres grandes con maldad tremenda
          <br />
          fabrican muerte ante tanta vida.
          <br />
          &nbsp;
          <br />
          Lo humano nace, crece y muere,
          <br />
          una constante precisa
          <br />
          que maneja el reloj
          <br />
          de los límites y las ideas.
          <br />
          &nbsp;
          <br />
          El cauce de lo vivo
          <br />
          riega la flora verde de árboles,
          <br />
          fecunda en flores, colores naturales,
          <br />
          alimenta con la canción de siempre
          <br />
          la fauna que recorre la alfombra de la vida.
          <br />
          &nbsp;
          <br />
          Ayer ví un caballo muerto
          <br />
          en la soledad de un campo,
          <br />
          cuando pasaba con mi paso obligado.
          <br />
          Festín de depredadores,
          <br />
          luego los insectos,
          <br />
          hasta comprobar que el cadáver
          <br />
          viaja de vuelta a la tierra
          <br />
          entregando su substancia.
          <br />
          Lo que vive vuelve,
          <br />
          eterno viaje.
          <br />
          El hombre entrega su carga,
          <br />
          su carne,
          <br />
          para que el desfile natural
          <br />
          siga pasando.
        </p>
      </center>
      <div class="autores">
        <div class="autor">
          <h6>Eduardo Ceballos.</h6>
          <img
            src="https://res.cloudinary.com/lagexpress/image/upload/f_auto,q_auto,r_max,q_100,w_100,h_100//strapi/Foto_a321272643"
            alt=""
          />
        </div>
      </div>
      <CommentsFB />
    </Layout>
  )
}

export default Poemas
